import React from 'react';

import { Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Opacity as OpacityIcon,
  List as ListIcon,
  Map as MapIcon,
  NotListedLocation,
} from '@material-ui/icons';

import Link from './Link';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const HomeBarButtons = () => {
  const classes = useStyles();

  return (
    <>
      <Fab disabled color="primary" className={classes.button}>
        <MapIcon />
      </Fab>

      <Tooltip title="Liste de stations">
        <Fab component={Link} color="primary" to="/stations" className={classes.button}>
          <ListIcon />
        </Fab>
      </Tooltip>

      <Tooltip title="Débits moyens journaliers">
        <Fab component={Link} color="primary" to="/seuils" className={classes.button}>
          <OpacityIcon />
        </Fab>
      </Tooltip>

      <Tooltip title="Informations">
        <Fab component={Link} color="primary" to="/informations" className={classes.button}>
          <NotListedLocation />
        </Fab>
      </Tooltip>
    </>
  );
};

export default HomeBarButtons;
