import { withPrefix } from 'gatsby';

import { createControlComponent } from '@react-leaflet/core';
import { Control, DomUtil } from 'leaflet';

const Compass = Control?.extend({
  onAdd () {
    const img = DomUtil.create('img');
    img.src = withPrefix('/north.png');
    img.style.width = '34px';
    img.id = 'map-compass';
    return img;
  },

  onRemove () {},
});

const CompassControl = Compass
  ? createControlComponent(props => new Compass(props))
  : null;

export default CompassControl;
