import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import PrintButton from './PrintButton';

const useStyles = makeStyles(theme => ({
  leafletControlImitation: {
    backgroundColor: 'white',
    zIndex: 900,
    position: 'absolute',
    right: '10px',
    top: '105px',

    '& > .MuiIconButton-sizeSmall': {
      margin: theme.spacing(0, 0),
      padding: theme.spacing(1.2, 1.2),
    },
  },
}));

const PrintButtonOnMap = () => {
  const classes = useStyles();

  const [
    pinnedStations,
    legendPosition,
    showLegend,
  ] = useSelector(
    state => [
      state.pinnedStations,
      state.legendPosition,
      state.showLegend,
    ],
  );

  const hasMapActiveCards = pinnedStations.length > 0;
  const showMapLegend = legendPosition === 'map' && showLegend;
  const hasMapAsideElement = hasMapActiveCards || showMapLegend;

  const printControlClass = clsx('leaflet-bar', 'leaflet-control-container', classes.leafletControlImitation);

  // Prepare export composition configuration.
  const elementConfigurations = [
    {
      targetQuerySelector: '#map',
      ignoreClassString: 'leaflet-control-container',
      scale: 1,
    },
    { targetQuerySelector: '#map-compass', scale: 2 },
    { targetQuerySelector: '.leaflet-control-scale', scale: 2 },
  ];
  if (hasMapAsideElement) {
    elementConfigurations.push({ targetQuerySelector: '#map-aside', scale: 2 });
  }

  // Prepare filename for export.
  const selectedDate = useSelector(({ mainDate }) => mainDate);
  const selectedDateObject = new Date(selectedDate);
  const fileBaseName = `lvdlr_carte_${selectedDateObject.toLocaleDateString()}`;

  return (
    <Box className={printControlClass}>
      <PrintButton
        elementConfigurations={elementConfigurations}
        className={classes.sizeSmall}
        fileBaseName={fileBaseName}
      />
    </Box>
  );
};

export default PrintButtonOnMap;
