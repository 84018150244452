import React from 'react';
import ReactDOMServer from 'react-dom/server';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { makeStyles } from '@material-ui/core/styles';

import useToponyms from '../hooks/useToponyms';

const useStyles = makeStyles(theme => ({
  divIcon: {
    width: 'auto !important',
  },
  iconInner: {
    whiteSpace: 'nowrap',
    pointerEvents: 'none !important',
    fontStyle: 'italic',
    fontSize: '0.65rem',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#99999999',
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(1),
    textShadow: '0 0 1px #000a',
    transform: 'translate(-50%, -50%)',
  },
}));

const CustomHtmlIcon = ({ label, ...props }) => (
  <div {...props}>
    {label}
  </div>
);

const makeHtmlIcon = props => ReactDOMServer.renderToString(<CustomHtmlIcon {...props} />);

const ToponymsLayer = () => {
  const { toponyms } = useToponyms();
  const classes = useStyles();

  const stateZoom = useSelector(({ mapZoom }) => mapZoom);

  const map = useMap();
  const zoom = stateZoom || map.getZoom();

  return (
    <>
      {toponyms.map(toponym => {
        const label = toponym?.properties?.label;
        const coordinates = [...(toponym?.geometry?.coordinates || [])];
        coordinates.reverse();

        const { zoom_cat: zoomCat } = toponym?.properties;
        const zoomFrom = zoomCat + 7;

        if (zoomFrom > zoom) {
          return null;
        }

        if (coordinates.length === 0 || !label) {
          return null;
        }

        return (
          <Marker
            key={JSON.stringify(coordinates)}
            position={coordinates}
            pane="stations"
            icon={L.divIcon({
              html: makeHtmlIcon({ label, className: classes.iconInner }),
              className: clsx(classes.divIcon, classes[`z${zoomFrom}`]),
            })}
          />
        );
      })}
    </>
  );
};

export default ToponymsLayer;
