import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LegendWrapper from './LegendWrapper';
import MapAsideCards from './MapAsideCards';

const useStyles = makeStyles(theme => ({
  main: ({ showMapLegend, showAside, height }) => ({
    right: 0,
    height: showAside ? height : 0,
    left: showMapLegend ? 275 : 0,
    bottom: 12,
    pointerEvents: 'none',
    position: 'absolute',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'width ease 250ms',
    zIndex: 1,
  }),
  inner: ({ height, enableClickThrought }) => ({
    height,
    display: 'flex',
    alignItems: 'flex-start',
    overflowX: 'auto',
    overflowY: 'hidden',
    pointerEvents: enableClickThrought ? 'none' : 'auto',
    paddingBottom: theme.spacing(1),
    '& > *': {
      pointerEvents: 'auto',
    },
  }),
}));

const MapAside = ({ className, height = 282, ...props }) => {
  const [enableClickThrought, setClickThrought] = React.useState(true);
  const element = React.useRef(null);

  const [
    selectedStation,
    pinnedStations,
    legendPosition,
    showLegend,
  ] = useSelector(
    state => [
      state.selectedStation,
      state.pinnedStations,
      state.legendPosition,
      state.showLegend,
    ],
  );

  const showMapLegend = legendPosition === 'map' && showLegend;
  const showAside = [...pinnedStations, selectedStation].some(Boolean);
  const classes = useStyles({ height, enableClickThrought, showAside, showMapLegend });

  React.useLayoutEffect(() => {
    /**
     * Enable click through only if there is no scroll
     * otherwise set pointer to auto to enable scrolling with the mouse
     * (Un)scroll depends on scrollWidth and pinnedStations
     */
    setClickThrought(element?.current?.scrollWidth <= element?.current?.clientWidth);
  }, [element?.current?.scrollWidth, pinnedStations]);

  return (
    <>
      {showMapLegend && <LegendWrapper />}
      <Box
        id="map-aside"
        className={clsx({
          [className]: true,
          [classes.main]: true,
        })}
        {...props}
      >
        <Box
          ref={element}
          className={classes.inner}
        >
          <MapAsideCards />
        </Box>
      </Box>
    </>
  );
};

export default MapAside;
