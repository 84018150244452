import React from 'react';

import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import debounce from 'debounce-promise';
import { useDispatch, useSelector } from 'react-redux';

const autocomplete = debounce(
  async str => {
    const response = await fetch(`${process.env.GATSBY_LVDLR_API_URL}/api/autocomplete?term=${str}`);
    const results = await response.json();
    return results;
  },
  300,
  { leading: true },
);

const getOptionLabel = option => {
  if (!option) return null;
  switch (option?.type) {
    case 'city':
    case 'hydro_station':
      return `${option.label} (${option.code})`;
    default:
      return option?.label || '';
  }
};

const getOptionGroup = option => {
  switch (option?.type) {
    case 'city':
      return 'Commune';
    case 'hydro_station':
      return 'Station';
    case 'sub_management_unit':
      return 'Sous-unité de gestion';
    default:
      return null;
  }
};

const GeoSearch = () => {
  const highlightElementvalue = useSelector(({ highlightElement }) => highlightElement);
  const initialStateInput = highlightElementvalue ? getOptionLabel(highlightElementvalue) : '';
  const [inputValue, setInputValue] = React.useState(initialStateInput);
  const [options, setOptions] = React.useState([]);
  const dispatch = useDispatch();

  const handleInputChange = async (event, newValue, reason) => {
    // when clicking outside the geosearch, a reset reason is triggered
    // but we want to keep our value after a 'select-option' change
    // except when the user do clear (handleChange > 'clear')
    // or change the value (handleInputChange > 'input')
    if (reason !== 'reset') {
      setInputValue(newValue);
    }
  };

  const handleChange = (event, newValue, reason) => {
    if (reason === 'clear') {
      dispatch({ type: 'GEOFOCUS_HIDE' });
      setInputValue('');
    }

    if (!newValue) { return; }
    const { label, latitude, longitude, type, code, id } = newValue;

    if (reason === 'select-option') {
      setInputValue(label);
      // in case we are close to another other place, we want to de-activate
      // the previous highlight while changing the viewport
      dispatch({ type: 'GEOFOCUS_HIDE' });
      if (latitude && longitude) {
        dispatch({ type: 'MAP_UPD_VIEWPORT', center: { lat: latitude, lng: longitude }, zoom: 12 });
        if (code) {
          // timeout is used to highlight afterzooming
          setTimeout(() => {
            dispatch({ type: 'GEOFOCUS_HIGHLIGHT', label, optionType: type, ID: id });
          }, 2000);
        }
      }
    }
  };

  const doSearch = async str => {
    const results = await autocomplete(str);
    const formatedResults = (results || []).map(option => ({
      ...option,
      label: getOptionLabel(option),
    }));
    setOptions(formatedResults);
  };

  React.useEffect(() => {
    if (inputValue.length >= 2) {
      doSearch(inputValue);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      value={highlightElementvalue || null}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      groupBy={getOptionGroup}
      getOptionLabel={result => result?.label || ''}
      clearText="Effacer"
      closeText="Fermer"
      noOptionsText="Aucun résultat"
      renderInput={params => <TextField {...params} label="Centrer sur…" variant="outlined" />}
    />
  );
};

export default GeoSearch;
