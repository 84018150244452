import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useSegments = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allGeojsonFeature(filter: {layer: {eq: "segments"}}) {
        segments: nodes {
          properties {
            label
            id
            reference_hydro_station { code }
          }
          json { geometry }
        }
      }
    }
  `);

  const segments = React.useMemo(
    () => wrapper.segments.map(({ json, properties, ...segment }) => ({
      ...segment,
      properties: {
        ...properties,
        id: properties.id.toString(),
      },
      geometry: JSON.parse(json.geometry),
    })),
    [wrapper],
  );

  return segments;
};

export default useSegments;
