import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';

import Link from './Link';

const MapAsideCardTitle = ({ title, to, ...props }) => {
  const [enableTooltip, setEnableTooltip] = React.useState(false);
  const element = React.useRef(null);

  React.useLayoutEffect(() => {
    /**
     * Enable Tooltip only if text width is larger than container
     */
    if (element?.current?.scrollWidth > element?.current?.clientWidth) {
      setEnableTooltip(true);
    }
  }, [element]);

  return (
    <Tooltip title={enableTooltip ? title : ''}>
      <Typography variant="h5" {...props} ref={element}>
        <Link to={to}>{title}</Link>
      </Typography>
    </Tooltip>
  );
};

export default MapAsideCardTitle;
