import React from 'react';
import { Polyline } from 'react-leaflet';
import { GeoJSON } from 'leaflet';

import useSegments from '../hooks/useSegments';
import useSegmentsStatus from '../hooks/useSegmentsStatus';

const { geometryToLayer } = GeoJSON || {};

const SegmentsLayer = () => {
  const segments = useSegments();
  const statuses = useSegmentsStatus();

  return (
    <>
      {segments.map(segment => {
        const positions = geometryToLayer(segment.geometry).getLatLngs();
        const id = segment?.properties?.id;
        const { status: { color } = {} } = statuses[id] || {};

        if (positions) {
          return (
            <React.Fragment key={id}>
              {Boolean(color === 'white') && (
                <Polyline
                  key={`${id}-${color}-2`}
                  positions={positions}
                  color="grey"
                  pane="surfaces"
                  weight={5}
                  opacity={0.7}
                />
              )}
              <Polyline
                key={`${id}-${color}-1`}
                positions={positions}
                color={color || 'grey'}
                pane="surfaces"
                weight={3}
                opacity={color === 'white' ? 1 : 0.5}
              />
            </React.Fragment>
          );
        }

        return null;
      })}
    </>
  );
};

export default SegmentsLayer;
