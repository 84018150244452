import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useToponyms = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allGeojsonFeature(filter: {layer: {eq: "toponyms"}}) {
        features: nodes {
          json { geometry properties }
        }
      }
    }
  `);

  const toponyms = React.useMemo(
    () => wrapper.features.map(
      ({ json, ...rest }) => ({
        ...rest,
        ...Object.entries(json)
          .reduce((acc, [key, str]) => ({ ...acc, [key]: JSON.parse(str) }), {}),
      }),
    ),
    [wrapper],
  );

  return { toponyms };
};

export default useToponyms;
