import React from 'react';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontSize: '0.8rem',
  },
  common: {
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    display: 'inline-block',
    textAlign: 'center',
  },
}));

const LegendHeader = ({ className, label, square, symbol, ...props }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="overline"
      className={clsx(className, classes.wrapper)}
      {...props}
    >
      {label}
    </Typography>
  );
};

export default LegendHeader;
