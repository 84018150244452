import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'block',
    position: 'relative',
  },
  centered: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  circle: {
    borderRadius: '50%',
    display: 'block',
  },
  outer: {
    width: 16,
    height: 16,
    border: '1px solid',
    borderColor: '#aaa',
    boxShadow: '0 0 1px #000000aa',
  },
  inner: ({ color }) => ({
    opacity: 0.5,
    width: 16,
    height: 16,
    backgroundColor: color,
  }),
  scp: {
    fontWeight: 'bold',
    lineHeight: 1,
    fontSize: '0.7rem',
    color: '#fff',
    textShadow: '0 0 2px #000',
  },
});

const StationIcon = ({ className, isSpc, color, ...props }) => {
  const classes = useStyles({ color });

  return (
    <div {...props} className={clsx(classes.root, className)}>
      <div className={clsx(classes.centered, classes.circle, classes.outer)} />
      <div className={clsx(classes.centered, classes.circle, classes.inner)} />
      {Boolean(isSpc) && (
        <div className={clsx(classes.centered, classes.scp)}>C</div>
      )}
    </div>
  );
};

export default StationIcon;
