import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useZones = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allGeojsonFeature(filter: {layer: {eq: "zones"}}) {
        zones: nodes {
          properties {
            label
            id
            reference_hydro_station { code }
          }
          json { geometry }
        }
      }
    }
  `);

  const zones = React.useMemo(
    () => wrapper.zones.map(({ json, properties, ...zone }) => ({
      ...zone,
      properties: {
        ...properties,
        id: properties.id.toString(),
      },
      geometry: JSON.parse(json.geometry),
    })),
    [wrapper],
  );

  return zones;
};

export default useZones;
