import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

import LegendItem from './LegendItem';
import LegendHeader from './LegendHeader';

import { thresholdColors as colors } from '../helpers/commonHelpers';
import { colors as precipitationColors } from './PrecipitationLayer';

import useMedia from '../hooks/useMedia';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(1, 1, 1, 1),
    '&:last-child': {
      padding: theme.spacing(1, 1, 1, 1),
    },
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  symbol: {
    marginTop: theme.spacing(1),
  },
  spcSymbol: {
    fontWeight: 'bold',
  },
  toggleLegendPlaceWrapper: {
    marginTop: 'auto',
  },
  toggleLegendPlaceAlt: {
    textAlign: 'right',
  },
  toggleLegendPlace: {
    padding: 0,
  },
}));

const Legend = ({ component: Component = Box, className, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const toggleLegendPlace = () => dispatch({ type: 'LEGEND_PLACE_CHANGE' });

  const isTabletOrMobile = useMedia(
    ['(max-width: 1224px)'],
    [true],
    false,
  );

  const {
    isOnMap,
    withHydroLegend,
    withPrecipitation,
  } = useSelector(state => ({
    isOnMap: state.legendPosition === 'map',
    withHydroLegend: state.showHydroLegend,
    withPrecipitation: state.showPrecipitationLegend,
  }));
  return (
    <Component className={clsx(className, classes.cardContent)} {...props}>
      {withHydroLegend && (
      <>
        <LegendItem square color="#ffffff" label="Données incomplètes" />
        <LegendItem square color="#bdced9" label="Pas de débit de référence" />
        <LegendItem square color={colors[0]} label="Débit sans difficulté pour l'étiage" />
        <LegendItem square color={colors[1]} label="Débit inférieur au débit d'objectif (DOE)" />
        <LegendItem square color={colors[2]} label="Débit inférieur au débit d'alerte (DA)" />
        <LegendItem square color={colors[3]} label="Débit inférieur au débit d'alerte renforcé (DAR)" />
        <LegendItem square color={colors[4]} label="Débit inférieur au débit de crise (DCR)" />
        <LegendItem
          symbol={<span className={classes.spcSymbol}>C</span>}
          className={classes.symbol}
          color="#000000"
          label="Stations Service Prévention Crues"
        />
      </>
      )}
      {withPrecipitation && (
        <>
          <LegendHeader label="Lame d'eau" />
          <LegendItem square color={precipitationColors[8]} label="> 100 mm" />
          <LegendItem square color={precipitationColors[7]} label="50 - 100 mm" />
          <LegendItem square color={precipitationColors[6]} label="30 - 50 mm" />
          <LegendItem square color={precipitationColors[5]} label="20 - 30 mm" />
          <LegendItem square color={precipitationColors[4]} label="10 - 20 mm" />
          <LegendItem square color={precipitationColors[3]} label="5 - 10 mm" />
          <LegendItem square color={precipitationColors[2]} label="2 - 5 mm" />
          <LegendItem square color={precipitationColors[1]} label="0,1 - 2 mm" />
          <LegendItem square color={precipitationColors[0]} label="0 - 0,1 mm" />
        </>
      )}
      { Boolean(!isTabletOrMobile) && (
        <Box
          type="button"
          className={clsx({
            [classes.toggleLegendPlaceWrapper]: true,
            [classes.toggleLegendPlaceAlt]: !isOnMap,
          })}
        >
          <IconButton
            className={classes.toggleLegendPlace}
            color="primary"
            onClick={toggleLegendPlace}
          >
            {isOnMap
              ? <ArrowLeft />
              : <ArrowRight />}
          </IconButton>
        </Box>
      )}
    </Component>
  );
};

export default Legend;
