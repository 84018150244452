import React from 'react';
import { useSelector } from 'react-redux';

import useStations from '../hooks/useStations';
import MapAsideCard from './MapAsideCard';

const MapAsideCards = () => {
  const [selectedStation, pinnedStations] = useSelector(
    state => [state.selectedStation, state.pinnedStations],
  );

  const stations = useStations();

  const DisplayedStations = React.useMemo(
    () => [...pinnedStations, selectedStation].filter(Boolean).reduce((acc, stationCode) => ([
      ...acc,
      stations.find(({ properties: { code } }) => (code === stationCode)),
    ]), []),
    [pinnedStations, selectedStation, stations],
  );

  return (
    <>
      {DisplayedStations.map(station => {
        const id = station.properties.code;
        const isPinned = pinnedStations.includes(id);

        return (
          <MapAsideCard
            key={id}
            station={station}
            pinned={isPinned}
          />
        );
      })}
    </>
  );
};

export default MapAsideCards;
