import React from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';

import useStations from './useStations';

import { getStationsUrlFromDates } from '../helpers/commonHelpers';
import {
  formatLastDayData,
  getStatusRequestDate, getStationsStatusFromData,
} from '../helpers/statusHelpers';

const fetchStationsData = async (stationCodes, quantity, start, end) => {
  try {
    const result = await fetch(getStationsUrlFromDates(stationCodes, quantity, start, end));
    return result.json();
  } catch (error) {
    console.error(error);
  }

  return [];
};

const useStatus = () => {
  const date = useSelector(({ mainDate }) => mainDate);
  const { dateYesterdayStart00H00, dateYesterdayEnd23H59 } = getStatusRequestDate(date);

  const stations = useStations();
  let stationsData = React.useMemo(() => {}, []);

  const stationsCodesOnlyH = stations
    .filter(({ properties: { is_spc } }) => is_spc)
    .map(({ properties: { code } }) => code);

  const stationsCodesQmj = stations
    .filter(({ properties: { is_spc } }) => !is_spc)
    .map(({ properties: { code } }) => code);

  const stationsLastDayQuery = useQueries([
    {
      queryKey: ['stationLastDayH', date],
      queryFn: () => fetchStationsData(stationsCodesOnlyH, 'H', dateYesterdayStart00H00, dateYesterdayEnd23H59),
    },
    {
      queryKey: ['stationLastDayQmj', date],
      queryFn: () => fetchStationsData(stationsCodesQmj, 'QmJ', dateYesterdayStart00H00, dateYesterdayEnd23H59),
    },
  ]);

  const queriesAreLoading = stationsLastDayQuery.some(({ isLoading }) => isLoading === true);

  const stationsLastDayData = queriesAreLoading
    ? [[], []]
    : stationsLastDayQuery.map(({ data }) => data);

  stationsData = formatLastDayData(stationsLastDayData);

  const stationsStatus = React.useMemo(
    () => (
      getStationsStatusFromData(queriesAreLoading, stations, stationsData)
    ),
    [queriesAreLoading, stations, stationsData],
  );

  return { stationsStatus };
};

export default useStatus;
