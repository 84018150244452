import dayjs from 'dayjs';

import { apiTimeFormat, storeDateFormat, getEndOfDay23H59, getStartOfDay00H00 } from './commonHelpers';

export const getExceededThreshold = (thresholds, value) => {
  const { doe, dar, da, dcr } = thresholds;
  // if there are no thresholds, this attribute is not relevant
  if (doe === null || !value) {
    return null;
  }
  if (value < doe && value >= da) {
    return 'doe';
  }
  if (value < da && value >= dar) {
    return 'da';
  }
  if (value < dar && value >= dcr) {
    return 'dar';
  }
  if (value < dcr) {
    return 'dcr';
  }

  return 'none';
};

const getStatusFromThreshold = threshold => {
  switch (threshold) {
    case 'none':
      return 'statut_ok';
    case 'doe':
      return 'statut_doe';
    case 'da':
      return 'statut_da';
    case 'dar':
      return 'statut_dar';
    case 'dcr':
      return 'statut_dcr';
    default:
      return null;
  }
};

export const statuses = {
  statut_h_donnees_indispo: { color: 'grey', description: 'données uniquement en hauteur et données récentes indisponibles' },
  statut_h: { color: 'grey', description: 'données uniquement en hauteur' },
  statut_dcr: { color: 'black', description: 'débit inférieur au débit de crise (DCR)' },
  statut_dar: { color: 'red', description: 'débit inférieur au débit d\'alerte renforcé (DAR)' },
  statut_da: { color: 'orange', description: 'débit inférieur au débit d\'alerte (DA)' },
  statut_doe: { color: 'yellow', description: 'débit inférieur au débit d\'objectif (DOE)' },
  statut_ok: { color: 'green', description: 'débit sans difficulté pour l\'étiage' },
  statut_sans_ref: { color: 'grey', description: 'pas de débit de référence' },
  statut_donnes_indispo: { color: 'white', description: 'données récentes indisponibles' },
};

export const statusIfNoReferenceStation = statuses.statut_sans_ref;

export const getStationStatusName = (thresholds, is_spc, yesterdayValue) => {
  if (is_spc && !yesterdayValue) {
    return 'statut_h_donnees_indispo';
  }

  if (is_spc) {
    return 'statut_h';
  }

  if (!yesterdayValue) {
    return 'statut_donnes_indispo';
  }

  if (!thresholds) {
    return 'statut_sans_ref';
  }

  // otherwise we have data and thresholds, we check if any of them have been exceeded
  const threshold = getExceededThreshold(thresholds, yesterdayValue);
  return getStatusFromThreshold(threshold);
};

export const getStatusFromStations = (stationsStatus, stationReference) => {
  const { status } = stationsStatus[stationReference] || { status: statusIfNoReferenceStation };
  return status;
};

export const getItemStatus = ({ id, stationReference }, stationsStatus) => {
  const status = stationReference
    ? getStatusFromStations(stationsStatus, stationReference)
    : statusIfNoReferenceStation;

  return {
    featureId: id,
    status,
  };
};

export const computeStationsStatus = (stations, stationsData) => {
  const stationsStatus = {};

  stations.forEach(({ properties: { code, is_spc, thresholds } }) => {
    const stationsStatusName = getStationStatusName(
      thresholds,
      is_spc,
      stationsData[code], // yesterdayValue
    );

    const stationStatus = statuses[stationsStatusName];

    stationsStatus[code] = {
      featureId: code,
      status: stationStatus,
    };
  });

  return stationsStatus;
};

export const computeItemsStatus = (zones, stationsStatus) => {
  const itemsStatus = {};

  zones.forEach(zone => {
    const { id } = zone;
    itemsStatus[id] = getItemStatus(zone, stationsStatus);
  });

  return itemsStatus;
};

export const formatLastDayData = (stationsLastDayData = [[], []]) => {
  // result come as two arrays (one for each query : H / QmJ)
  // for each timstamp we have a { d, v: { code: value } } object
  const [Hdata, QmJdata] = stationsLastDayData;

  // keeping the most recent value
  // for H values, we want to keep the last one (the granularity is high)
  // for QmJ, 0 or one will should be returned (one value per day).
  const [lastHdata] = Hdata.slice(-1);
  const [lastQmJdata] = QmJdata.slice(-1);

  const { v: lastQmJvalues } = lastQmJdata || { v: null };
  const { v: lastHvalues } = lastHdata || { v: null };

  // we want a list of station with values as an output
  return { ...lastHvalues, ...lastQmJvalues };
};

export const getStatusRequestDate = date => {
  const yesterday = dayjs(date, storeDateFormat).subtract(1, 'day');

  const dateYesterdayStart00H00 = getStartOfDay00H00(yesterday).format(apiTimeFormat);
  const dateYesterdayEnd23H59 = getEndOfDay23H59(yesterday).format(apiTimeFormat);

  return { dateYesterdayStart00H00, dateYesterdayEnd23H59 };
};

export const getStationsStatusFromData = (queriesAreLoading, stations, stationsData) => {
  const emptyStatus = {};
  if (queriesAreLoading) {
    return emptyStatus;
  }

  return computeStationsStatus(stations, stationsData);
};
