import React from 'react';
import clsx from 'clsx';

import { Box, Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import Layout from '../components/Layout';
import Map from '../components/Map';
import MapAside from '../components/MapAside';
import MapCustomStyles from '../components/MapCustomStyles';
import HomeBar from '../components/HomeBar';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.homeDrawer.width.main + 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: theme.homeDrawer.width.main + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 100,
    }),
    overflowX: 'hidden',
    width: theme.homeDrawer.width.narrow + 1,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
  },
  buttonWrapper: {
    height: theme.spacing(2),
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(({ homeDrawerOpen }) => homeDrawerOpen);

  const handleDrawerToggle = force => () => dispatch({ type: 'HOME_DRAWER_TOGGLE', force });

  return (
    <Layout
      noHeader
      noFooter
      disableGutters
      maxWidth={false}
      title="Carte générale"
      style={{
        flex: '1',
        display: 'flex',
        alignItems: 'stretch',
      }}
    >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box
          align="right"
          className={clsx({
            [classes.buttonWrapper]: open,
          })}
        >
          <IconButton onClick={handleDrawerToggle()}>
            {open ? <NavigateBefore /> : <NavigateNext />}
          </IconButton>
        </Box>

        <HomeBar collapsed={!open} />
      </Drawer>

      <Box id="map" className={classes.content}>
        <MapAside />
        <MapCustomStyles>
          <Map />
        </MapCustomStyles>
      </Box>
    </Layout>
  );
};

export default Home;
