import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, Tooltip } from 'react-leaflet';
import { divIcon, GeoJSON } from 'leaflet';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

import useStations from '../hooks/useStations';
import useStationsStatus from '../hooks/useStationsStatus';
import useMedia from '../hooks/useMedia';
import StationIcon from './StationIcon';
import { fromSmallScreenMediaQuery } from '../helpers/commonHelpers';

const { geometryToLayer } = GeoJSON || {};

const tooltipOffset = [15, 0];

const useStyles = makeStyles({
  divIcon: {
    border: 'none',
  },
});

const StationsLayer = () => {
  const classes = useStyles();

  const { isLoading, stationsStatus: statuses } = useStationsStatus();
  const stations = useStations();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMedia(
    // Media queries
    [fromSmallScreenMediaQuery],
    // values (relates to above media queries by array index)
    [true],
    // default value
    false,
  );

  const getEventMap = React.useCallback(
    station => ({
      click: () => {
        const stationCode = station?.properties?.code;
        if (isTabletOrMobile) {
          navigate(`/station/${stationCode}`);
        } else {
          dispatch({ type: 'STATION_PIN', stationCode });
        }
      },
    }),
    [isTabletOrMobile, dispatch],
  );

  if (isLoading) {
    return (<></>);
  }

  return (
    <>
      {stations.map(station => {
        const coordinates = geometryToLayer(station.geometry).getLatLng();
        const { id, is_spc: isSpc } = station.properties || {};
        const { status: { color, description } = {} } = statuses[id] || {};
        if (coordinates) {
          const icon = divIcon({
            iconSize: undefined,
            className: classes.divIcon,
            html: ReactDOMServer.renderToString((
              <StationIcon color={color} isSpc={isSpc} />
            )),
          });

          return (
            <Marker
              key={id}
              position={coordinates}
              pane="stations"
              eventHandlers={getEventMap(station)}
              icon={icon}
            >
              <Tooltip sticky offset={tooltipOffset}>
                <strong>{station.properties.label}</strong><br />
                {description && <span><em>({description})</em></span>}
              </Tooltip>
            </Marker>
          );
        }

        return null;
      })}
    </>
  );
};

export default StationsLayer;
