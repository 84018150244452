import React from 'react';

import { Helmet } from 'react-helmet';
import { MapContainer, Pane, ScaleControl, ZoomControl } from 'react-leaflet';
import { ErrorBoundary } from 'react-error-boundary';

import { makeStyles } from '@material-ui/core/styles';

import BaseLayerControl from './BaseLayerControl';
import CompassControl from './CompassControl';
import MapLayers from './MapLayers';
import SyncedViewport from './SyncedViewport';
import PrintButtonOnMap from './PrintButtonOnMap';

const isLive = typeof window !== 'undefined';

const stationsPaneStyle = { zIndex: 610 };
const highlightPaneStyle = { zIndex: 480 };
const tooltipPopupMarkerPaneStyle = { zIndex: 460 };
const surfacesPaneStyle = { zIndex: 450 };

const FallbackComponent = () => (
  <div>Map component crash</div>
);

const useStyles = makeStyles({
  root: {
    height: 'auto',
    width: 'auto',
    minHeight: 100,
    minWidth: 100,
    flex: '1 1 auto',
  },
});

const Map = () => {
  const classes = useStyles();
  if (!isLive) { return null; }

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <Helmet>
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
      </Helmet>

      <MapContainer
        preferCanvas
        bounds={[[45.3852, -0.1450], [43.7669, 4.0844]]}
        className={classes.root}
        zoomSnap={0.5}
        zoomDelta={0.5}
        zoomControl={false}
      >
        <SyncedViewport />

        <Pane name="stations" style={stationsPaneStyle} />
        <Pane name="highlight" style={highlightPaneStyle} />
        <Pane name="tooltipPopupMarker" style={tooltipPopupMarkerPaneStyle} />
        <Pane name="surfaces" style={surfacesPaneStyle} />

        <CompassControl position="topleft" />
        <ZoomControl position="topleft" />
        <ScaleControl position="topright" />
        <BaseLayerControl position="topright" />
        <MapLayers />

        <PrintButtonOnMap />
      </MapContainer>
    </ErrorBoundary>
  );
};

export default Map;
