import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeoJSON } from 'leaflet';

import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  PinDrop as PinDropIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import CardTitle from './MapAsideCardTitle';
import Link from './Link';
import StationChart from './StationChart';
import StationDataToggleButtons from './StationDataToggleButtons';
import useToggleState from './useToggleState';
import PrintButton from './PrintButton';
import { availableDataModes } from '../helpers/commonHelpers';

const { geometryToLayer } = GeoJSON || {};

const useStyles = makeStyles(theme => ({
  card: {
    marginLeft: theme.spacing(1),
    width: 420,
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'stretch',
  },
  cardName: {
    padding: theme.spacing(1, 0),
  },
  cardNameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardButtons: {
    flex: '0 0 auto',
  },
  cardContent: {
    padding: theme.spacing(0, 1, 1, 1),
    '&:last-child': {
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
}));

const MapAsideCard = ({ station }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const center = geometryToLayer(station.geometry).getLatLng();
  const stationCode = station.properties.code;
  // the order of thresholds is given here by the hook useStations in MapAsideCards
  const stationThresholds = useMemo(() => station.properties.thresholds, [station]);
  const vcn3 = useMemo(() => station.vcn3 || [], [station])
  const vcn10 = useMemo(() => station.vcn10 || [], [station])
  const zoom = 12;

  const currentAvailableDataModes = React.useMemo(
    () => availableDataModes(station.properties.quantities),
    [station],
  );

  const [
    activeDataModeId,
    handleDataModeChange,
    activeAnteriorityId,
    handleAnteriorityChange,
  ] = useToggleState(currentAvailableDataModes);

  const unpinStation = ({ ctrlKey }) => (
    ctrlKey
      ? dispatch({ type: 'STATION_UNPIN_ALL' })
      : dispatch({ type: 'STATION_UNPIN', stationCode })
  );

  const centerStation = () => dispatch({ type: 'MAP_UPD_VIEWPORT', center, zoom });

  // Prepare filename for export.
  const selectedDate = useSelector(({ mainDate }) => mainDate);
  const selectedDateObject = new Date(selectedDate);
  const fileBaseName = `lvdlr_${stationCode}_${activeDataModeId}_${selectedDateObject.toLocaleDateString()}`;

  return (
    <Card variant="outlined" id={`stationcard${stationCode}`} square className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container justifyContent="space-between" wrap="nowrap">
          <Grid item className={classes.cardName} zeroMinWidth>
            <CardTitle
              title={station.properties.label}
              to={`/station/${stationCode}`}
              className={classes.cardNameText}
            />
          </Grid>

          <Grid item id="cardButtons" className={classes.cardButtons} data-html2canvas-ignore>
            <Tooltip title="Ouvrir la fiche complète de la station" arrow>
              <IconButton color="primary" size="small" component={Link} to={`/station/${stationCode}`}>
                <FullscreenIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Centrer la carte sur cette station" arrow>
              <IconButton color="primary" size="small" onClick={centerStation}>
                <PinDropIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>

            <PrintButton
              elementConfigurations={[{ targetQuerySelector: `#stationcard${stationCode}`, scale: 2 }]}
              a4PDFPage={false}
              buttonProps={{ color: 'primary' }}
              iconProps={{ fontSize: 'inherit' }}
              fileBaseName={fileBaseName}
            />

            <Tooltip title="Fermer" arrow>
              <IconButton color="primary" size="small" onClick={unpinStation} edge="end">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Box variant="outlined" className={classes.cardData}>
          <StationDataToggleButtons
            activeDataModeId={activeDataModeId}
            handleDataModeChange={handleDataModeChange}
            activeAnteriorityId={activeAnteriorityId}
            handleAnteriorityChange={handleAnteriorityChange}
            dense
            availableDataModes={currentAvailableDataModes}
            data-html2canvas-ignore
          />

          <StationChart
            id={stationCode}
            mode={activeDataModeId}
            anteriorityId={activeAnteriorityId}
            table={false}
            height={130}
            dense
            thresholds={stationThresholds}
            vcn3={vcn3}
            vcn10={vcn10}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MapAsideCard;
