import React from 'react';

import useSegments from './useSegments';
import useStationsStatus from './useStationsStatus';

import { computeItemsStatus } from '../helpers/statusHelpers';

export const useSegmentsStatus = () => {
  const { stationsStatus } = useStationsStatus();

  const segments = useSegments().map(({
    properties: { id, reference_hydro_station: referenceStation },
  }) => (
    {
      id,
      stationReference: referenceStation?.code || null,
    }
  ));

  const status = React.useMemo(
    () => computeItemsStatus(segments, stationsStatus),
    [segments, stationsStatus],
  );

  return status;
};

export default useSegmentsStatus;
