import React from 'react';
import { Polygon, Tooltip } from 'react-leaflet';
import { GeoJSON } from 'leaflet';

import { makeStyles } from '@material-ui/core/styles';

import useZones from '../hooks/useZones';
import useZonesStatus from '../hooks/useZonesStatus';

const useStyles = makeStyles(theme => ({
  zoneTooltip: {
    padding: `${theme.spacing(0.5, 1)} !important`,
    fontSize: '0.7rem',
  },
}));

const { geometryToLayer } = GeoJSON || {};

const tooltipOffset = [15, 0];

const ZonesLayer = () => {
  const classes = useStyles();
  const zones = useZones();
  const statuses = useZonesStatus();

  return (
    <>
      {zones.map(zone => {
        const positions = geometryToLayer(zone.geometry).getLatLngs();
        const id = zone?.properties?.id;
        const { status: { color } = {} } = statuses[id] || {};

        if (positions) {
          return (
            <Polygon
              key={id + color}
              positions={positions}
              fillColor={color}
              color={color === 'white' ? 'grey' : color}
              pane="surfaces"
              weight={1}
              fillOpacity={0.1}
              opacity={1}
            >
              <Tooltip
                sticky
                offset={tooltipOffset}
                className={classes.zoneTooltip}
              >
                Unité de gestion :<br />
                <strong>{zone.properties.label}</strong>
              </Tooltip>
            </Polygon>
          );
        }

        return null;
      })}
    </>
  );
};

export default ZonesLayer;
