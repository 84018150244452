import { withPrefix } from 'gatsby';
import React from 'react';
import { useQuery } from 'react-query';
import { GeoJSON } from 'react-leaflet';
import { extractGeojsonIDFromURL } from '../helpers/commonHelpers';

import useGeojsonFiles from '../hooks/useGeojsonFiles';

const getLayerSourcePath = filename => withPrefix(`/geojson/${filename}`);

const useLayerData = (filename, localData) =>
  useQuery(
    ['AsyncMapLayer', filename, localData],
    async () => {
      if (filename && !localData) {
        const realUrl = filename.indexOf('/') === -1 ? getLayerSourcePath(filename) : filename;
        const response = await fetch(realUrl);
        const json = await response.json();

        return json;
      }
      return null;
    },
    { retry: 1, staleTime: Infinity },
  );

const AsyncMapLayer = ({
  component: Component = GeoJSON,
  styleJSON,
  filename: url,
  ...props
}) => {
  const geojsonFiles = useGeojsonFiles();
  // first, we look for it in the remote geojson files
  // available in our graphql database
  const [remoteFile] = geojsonFiles
    .filter(({ name: id }) => id === extractGeojsonIDFromURL(url));
  const remoteGeojson = remoteFile?.geojson;
  // second look in static files in /geojson folder
  // this methods should disappear when all those files
  // will be served from the backend
  const { data: staticData } = useLayerData(url, remoteGeojson);
  const data = staticData || remoteGeojson;

  const style = React.useCallback(() => {
    const defaultStyle = {
      weight: 1,
      radius: 8,
    };
    if (!styleJSON) { return defaultStyle; }

    try {
      const layerStyle =  JSON.parse(styleJSON);
      return {
        ...defaultStyle,
        ...layerStyle,
      };
    } catch (e) {
      return defaultStyle;
    }
  }, [styleJSON]);

  if (!data) {
    return <></>;
  }

  return (
    <Component style={style} data={data} {...props} />
  );
};

export default AsyncMapLayer;
