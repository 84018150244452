import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import useLayers from '../hooks/useLayers';
import { layerTypesWithURL } from '../helpers/layersHelpers';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  groupName: {
    '& .MuiTypography-body2': {
      fontWeight: 500,
    },
  },
}));

const Layers = () => {
  const classes = useStyles();

  const groups = useLayers();

  const [groupStates, setGroupStates] = React.useState({});
  const toggleGroup = group => () =>
    setGroupStates(prevState => ({ ...prevState, [group]: !prevState[group] }));

  const activeLayers = useSelector(({ activeLayers: aLayers }) => aLayers);

  const dispatch = useDispatch();
  const toggle = (id, type) => () => dispatch({
    type: 'LAYER_TOGGLE',
    layers: [{ id, type }],
  });

  const shouldLayerBeDisabled = layer => {
    // If this layer doesn't need a URL, never disable it.
    if (!layerTypesWithURL.includes(layer.type)) {
      return false;
    }

    // If it does need a URL, disable it if no URL was provided.
    return layer.url === '' || layer.url === null || layer.url === undefined;
  };

  return (
    <List>
      {groups.map(({ id: groupId, label, layers }) => {
        if (groupId === 'none') {
          return <React.Fragment key={groupId} />;
        }

        const activeChildCount = layers.filter(
          layer => activeLayers.some(activeLayer => activeLayer.id === layer.id),
        ).length;

        return (
          <React.Fragment key={groupId}>
            <ListItem button onClick={toggleGroup(groupId)}>
              <ListItemText primary={label} className={classes.groupName} />
              {Boolean(activeChildCount) && (
                <Chip size="small" label={activeChildCount} color="secondary" />
              )}
              {groupStates[groupId] && <ExpandLess />}
              {!groupStates[groupId] && <ExpandMore />}
            </ListItem>

            <Collapse in={groupStates[groupId]} timeout="auto" unmountOnExit>
              {layers.map(layer => {
                const isLayerDisabled = shouldLayerBeDisabled(layer);

                return (
                  <ListItem
                    disabled={isLayerDisabled}
                    button
                    key={layer.id}
                    className={classes.nested}
                    onClick={toggle(layer.id, layer.type)}
                  >
                    <ListItemText primary={layer.label} />
                    <ListItemSecondaryAction>
                      <Switch
                        size="small"
                        // TODO: Créer fonction qui permet de savoir si ce type
                        //   de calque permet d'avoir une URL ou non.
                        //   + Mettre à jour données coté back (retirer URLs)
                        disabled={isLayerDisabled}
                        edge="end"
                        onChange={toggle(layer.id, layer.type)}
                        checked={activeLayers.some(activeLayer => activeLayer.id === layer.id)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </Collapse>
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default Layers;
