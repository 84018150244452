import { useQuery } from 'react-query';
import { parseCustomStringDateAndFormat } from '../helpers/commonHelpers';

const usePrecipitation = requestedDate => {
  const date = parseCustomStringDateAndFormat(requestedDate);

  return useQuery(
    ['precipitation', date],
    async () => {
      const parsedUri = `data/geojson/antilope/antilopeq_${date}.geojson`;
      const response = await fetch(`${process.env.GATSBY_LVDLR_API_URL}/${parsedUri}`);
      return response.json();
    },
    {
      retry: 1,
      staleTime: Infinity,
    },
  );
};

export default usePrecipitation;
