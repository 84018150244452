import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: ({ mapPadding: [top, right, bottom, left] = [0, 0, 0, 0] }) => ({
    height: 'auto',
    width: 'auto',
    minHeight: 100,
    minWidth: 100,
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',
    zIndex: 0,

    '& .leaflet-top': { top },
    '& .leaflet-right': { right },
    '& .leaflet-bottom': { bottom },
    '& .leaflet-left': { left },
  }),
});
const MapCustomStyles = props => {
  const mapPadding = useSelector(state => state.mapPadding);
  const classes = useStyles({ mapPadding });

  return (
    <Box className={classes.root} {...props} />
  );
};

export default MapCustomStyles;
