import React from 'react';

import useZones from './useZones';
import useStationsStatus from './useStationsStatus';

import { computeItemsStatus } from '../helpers/statusHelpers';

export const useZonesStatus = () => {
  const { stationsStatus } = useStationsStatus();

  const zones = useZones().map(({
    properties: { id, reference_hydro_station: referenceStation },
  }) => (
    {
      id,
      stationReference: referenceStation?.code || null,
    }
  ));

  const status = React.useMemo(
    () => computeItemsStatus(zones, stationsStatus),
    [zones, stationsStatus],
  );

  return status;
};

export default useZonesStatus;
