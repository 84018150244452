import { graphql, useStaticQuery } from 'gatsby';

export const useLayers = () => {
  const { allLayerGroups: { nodes } } = useStaticQuery(graphql`
    {
      allLayerGroups {
        nodes {
          id
          label
          layers {
            label
            type
            url
            isDisplayedByDefault
            attribution
            style
            settings
            id
          }
        }
      }
    }
  `);

  return nodes;
};

export default useLayers;
