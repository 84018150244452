import { useMap, useMapEvent } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react'

const SyncedViewport = () => {
  const map = useMap();
  const dispatch = useDispatch();
  const [center, zoom] = useSelector(({ mapCenter, mapZoom }) => [mapCenter, mapZoom]);

  useMapEvent('moveend', () => dispatch({ type: 'MAP_UPD_VIEWPORT', center: map.getCenter(), zoom: map.getZoom() }));

  const currentCenter = map.getCenter();
  const currentZoom = map.getZoom();

  const centerStr = JSON.stringify(center);
  const currentCenterStr = JSON.stringify(currentCenter);

  const shouldUpdate = (currentCenterStr !== centerStr) || (currentZoom !== zoom);

  const syncedViewportRef = React.useRef();

  // Observe viewport's size. Whenever it changes, recompute Leaflet's size.
  React.useEffect(() => {
    const observeTargetHtml = syncedViewportRef.current;

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      map.invalidateSize({ debounceMoveend: true });
    });

    resizeObserver.observe(observeTargetHtml);

    return () => {
      resizeObserver.unobserve(observeTargetHtml);
    };
  }, [map, syncedViewportRef]);

  if (shouldUpdate && (zoom || center)) {
    if (zoom && !center) {
      map.setZoom(zoom);
    } else if (center && !zoom) {
      map.flyTo(center);
    } else if (center && zoom) {
      map.flyTo(center, zoom);
    }
  }

  return (
    <div
      ref={syncedViewportRef}
      className="map-synced-viewport"
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default SyncedViewport;
