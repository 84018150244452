import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { useSelector } from 'react-redux';
import usePrecipitation from '../hooks/usePrecipitation';

export const colors = [
  '#ffffff',
  '#bee7ff',
  '#6fe1fc',
  '#33fd8d',
  '#85fa09',
  '#ffde01',
  '#f17101',
  '#81010a',
  '#3a0905',
  '#ffffff',
];
const PrecipitationLayer = ({
  filename,
  ...props
}) => {
  const currentMainDate = useSelector(({ mainDate }) => mainDate);
  const { data } = usePrecipitation(currentMainDate);
  const style = React.useCallback(feature => {
    const precipitationCat = feature.properties?.precipitation_cat || 9;
    return {
      fillColor: colors[precipitationCat],
      color: 'transparent',
      fillOpacity: 0.6,
    };
  }, []);

  if (!data) {
    return <></>;
  }

  return (
    <GeoJSON key={currentMainDate} style={style} data={data} {...props} />
  );
};

export default PrecipitationLayer;
