import React from 'react';

import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Legend from './Legend';

const useStyles = makeStyles(theme => ({
  legendWrapper: {
    width: 275,
    left: 0,
    top: 90,
    bottom: 0,
    pointerEvents: 'none',
    position: 'absolute',
    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'width ease 250ms',
    backgroundColor: 'transparent',
    zIndex: 1001,
    display: 'flex',
    alignItems: 'flex-end',
  },
  legendInner: ({ enableClickThrought }) => ({
    height: 'inherit',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
    pointerEvents: enableClickThrought ? 'none' : 'auto',
    '& > *': {
      pointerEvents: 'auto',
    },
  }),
  legend: {
    minWidth: 200,
  },
  card: {
    marginLeft: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
  },
}));

const LegendWrapper = () => {
  const element = React.useRef(null);
  const [enableClickThrought, setClickThrought] = React.useState(true);
  React.useLayoutEffect(() => {
    /**
     * Enable click through only if there is no scroll
     * otherwise set pointer to auto to enable scrolling with the mouse
     * (Un)scroll depends on scrollWidth and pinnedStations
     */
    setClickThrought(element?.current?.scrollWidth <= element?.current?.clientWidth);
  }, [element?.current?.scrollWidth]);

  const classes = useStyles({ enableClickThrought });

  return (
    <Box
      id="map-legend"
      className={classes.legendWrapper}
    >
      <Box
        ref={element}
        className={classes.legendInner}
      >
        <Card variant="outlined" square className={classes.card}>
          <Legend component={CardContent} className={classes.legend} />
        </Card>
      </Box>
    </Box>
  );
};

export default LegendWrapper;
