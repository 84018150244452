import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useGeojsonFiles = () => {
  const { wrapper: { nodes } } = useStaticQuery(graphql`
    query {
      wrapper: allFile(filter: {internal: {mediaType: {eq: "application/geo+json"}}}) {
        nodes {
          name
          internal {
            content
          }
        }
      }
    }
  `);

  const geojsonFiles = React.useMemo(
    () => nodes.map(({ name, internal: { content } }) => ({
      name,
      geojson: JSON.parse(content),
    })),
    [nodes],
  );

  return geojsonFiles;
};

export default useGeojsonFiles;
