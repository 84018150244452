import React from 'react';
import clsx from 'clsx';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontSize: '0.7rem',
  },
  common: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(0.5),
    border: '1px solid transparent',
    display: 'inline-block',
    textAlign: 'center',
  },
  square: ({ color }) => ({
    backgroundColor: color,
    verticalAlign: -3,
    borderColor: 'currentColor',
  }),
  symbol: ({ color }) => ({
    color,
  }),
}));

const LegendItem = ({ className, label, color = '#ffffff', square, symbol, ...props }) => {
  const classes = useStyles({ color });

  return (
    <Typography
      variant="body2"
      className={clsx(className, classes.wrapper)}
      {...props}
    >
      <Box
        className={clsx({
          [classes.common]: true,
          [classes.square]: square,
          [classes.symbol]: symbol,
        })}
        component="span"
      >
        {symbol}
      </Box>
      {label}
    </Typography>
  );
};

export default LegendItem;
