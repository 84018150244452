import React from 'react';
import clsx from 'clsx';
import { withPrefix } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layers from './Layers';
import LayersDispatch from './LayersDispatch';
import Link from './Link';
import GeoSearch from './GeoSearch';
import HomeBarButtons from './HomeBarButtons';
import CustomDatePicker from './CustomDatePicker';
import CurrentDate from './CurrentDate';
import Legend from './Legend';
import useMedia from '../hooks/useMedia';
import { fromMediumScreenMediaQuery } from '../helpers/commonHelpers';

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'normal',
  },
  margins: {
    padding: theme.spacing(0, 1),
  },
  logo: {
    display: 'block',
    width: 200,
    margin: '0 auto',
  },
  smallLogo: {
    display: 'block',
    width: '100%',
  },
}));

const HomeBar = ({ collapsed = false }) => {
  const classes = useStyles();
  const [
    showLegend,
    legendPosition,
  ] = useSelector(state => [
    state.showLegend,
    state.legendPosition,
  ]);

  const isTabletOrMobile = useMedia(
    [fromMediumScreenMediaQuery],
    [true],
    false,
  );

  const dispatch = useDispatch();
  React.useEffect(
    () => {
      if (isTabletOrMobile && (legendPosition === 'map')) {
        dispatch({ type: 'LEGEND_PLACE_CHANGE' });
      }
    },
    [isTabletOrMobile, legendPosition, dispatch],
  );

  const showSideLegend = legendPosition === 'side' && showLegend;

  return (
    <Box className={classes.root}>
      <Box className={clsx({ [classes.margins]: !collapsed })}>
        <Link to="/">
          <img
            src={withPrefix('/logo.png')}
            alt=""
            className={clsx({
              [classes.smallLogo]: collapsed,
              [classes.logo]: !collapsed,
            })}
          />
        </Link>

        {!collapsed && (
          <>
            <Typography variant="h2" component="h1">
              La Vie<br />De La Rivière
            </Typography>

            <Typography variant="body2">
              La vie du bassin-versant du Lot<br />(rivière Lot et affluents)
            </Typography>
          </>
        )}

        <Box align="center">
          <HomeBarButtons />

          {Boolean(collapsed) && <CurrentDate />}
        </Box>

        {!collapsed && <CustomDatePicker inputVariant="outlined" fullWidth label="Choisir la date" />}

        {!collapsed && <GeoSearch />}
      </Box>

      {!collapsed && <Layers />}

      {!collapsed && showSideLegend && <Legend />}

      <LayersDispatch />

    </Box>
  );
};

export default HomeBar;
