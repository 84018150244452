import React from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';

import useSiteMetadata from '../hooks/useSiteMetadata';
import useLayers from '../hooks/useLayers';
import { TYPE_TILE_LAYER } from '../helpers/layersHelpers';

const BaseLayerControl = ({ position }) => {
  const { ignToken } = useSiteMetadata();
  const groups = useLayers();
  const baseLayers = [];
  // Keep all layers with isBase true.
  groups.forEach(group => {
    (group.layers || []).forEach(layer => {
      if (layer.type === TYPE_TILE_LAYER) {
        baseLayers.push(layer);
      }
    });
  });

  return (
    <LayersControl position={position}>
      {baseLayers.map(({ label, url, isDisplayedByDefault, attribution }) => (
        <LayersControl.BaseLayer name={label} key={url} checked={isDisplayedByDefault}>
          <TileLayer
            attribution={attribution}
            url={url.replace('{token}', ignToken)}
          />
        </LayersControl.BaseLayer>
      ))}
    </LayersControl>
  );
};

export default BaseLayerControl;
