import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { site: { siteMetadata } } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          ignToken
        }
      }
    }
  `);

  return siteMetadata;
};

export default useSiteMetadata;
