import React from 'react';
import { GeoJSON, WMSTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import 'font-awesome/css/font-awesome.css';
import 'leaflet.awesome-markers';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';

import useLayers from '../hooks/useLayers';
import AsyncMapLayer from './AsyncMapLayer';
import StationsLayer from './StationsLayer';
import ZonesLayer from './ZonesLayer';
import SegmentsLayer from './SegmentsLayer';
import ToponymsLayer from './ToponymsLayer';
import { highlightTypes } from '../helpers/commonHelpers';
import PrecipitationLayer from './PrecipitationLayer';
import {
  TYPE_WMS_TILE_LAYER,
  TYPE_GEOJSON,
  TYPE_STATION,
  TYPE_SUB_MANAGEMENT_UNIT,
  TYPE_HYDROGRAPHY,
  TYPE_TOPONYM,
  TYPE_PRECIPITATION,
} from '../helpers/layersHelpers';

const getPointToLayerCallback = layerStyleJSON => {
  // Default icons to the FontAwesome set.
  L.AwesomeMarkers.Icon.prototype.options.prefix = 'fa';

  const layerStyle = JSON.parse(layerStyleJSON);
  const markerOptions = {
    pane: 'tooltipPopupMarker',
  };

  if (layerStyle?.awesomeMarkersIconOptions) {
    markerOptions.icon = L.AwesomeMarkers.icon(layerStyle.awesomeMarkersIconOptions);
    markerOptions.icon.options.shadowSize = [0, 0];
    return (feature, latLng) => L.marker(latLng, markerOptions);
  }

  return (feature, latLng) => L.circleMarker(latLng, { pane: 'tooltipPopupMarker' });
};
const pointToLayerHighlight = (feature, latLng) => L.circleMarker(latLng, { pane: 'highlight' });

const MapLayers = () => {
  const groups = useLayers();
  const activeLayerNames = useSelector(state => state.activeLayers);
  const highlightElement = useSelector(state => state.highlightElement);
  const highlightInfo = highlightElement ? highlightTypes[highlightElement?.type] : { location: '', style: {} };
  const filterHighlightElementClosure = ID => function filter (geoJsonFeature) {
    return geoJsonFeature?.properties[highlightInfo.id] === ID;
  };

  const activeLayers = React.useMemo(
    () => groups.reduce((acc, { layers }) => ([
      ...acc,
      ...layers,
    ]), []).filter(({ id }) => activeLayerNames.some(activeLayer => activeLayer.id === id)),
    [activeLayerNames, groups],
  );

  const bindTooltipPopup = (feature, layer) => {
    const hasPopup = Object.prototype.hasOwnProperty.call(feature?.properties, 'popup-content');
    const hasTooltip = Object.prototype.hasOwnProperty.call(feature?.properties, 'tooltip-content');

    if (hasPopup) {
      layer.bindPopup(feature.properties['popup-content']);
    }
    if (hasTooltip) {
      layer.bindTooltip(feature.properties['tooltip-content']);
    }
  };

  return (
    <>
      {activeLayers.map(layer => {
        const { id, type, url, style, settings } = layer;

        /**
         * Use custom local layer for Stations
         */
        if (type === TYPE_STATION) {
          return <StationsLayer key={id} />;
        }

        /**
         * Use custom local layer for Zones
         */
        if (type === TYPE_SUB_MANAGEMENT_UNIT) {
          return <ZonesLayer key={id} />;
        }

        /**
         * Use custom local layer for Segments
         */
        if (type === TYPE_HYDROGRAPHY) {
          return <SegmentsLayer key={id} />;
        }

        /**
         * Use custom local layer for Toponymy
         */
        if (type === TYPE_TOPONYM) {
          return <ToponymsLayer key={id} />;
        }

        if (type === TYPE_PRECIPITATION) {
          return <PrecipitationLayer key={id} />;
        }

        if (type === TYPE_WMS_TILE_LAYER) {
          const layerProps = {
            key: id,
            url,
          };

          try {
            layerProps.params = JSON.parse(settings);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
          return (
            <WMSTileLayer {...layerProps} />
          );
        }

        if (type === TYPE_GEOJSON) {
          return (
            <AsyncMapLayer
              key={id}
              component={GeoJSON}
              filename={url}
              styleJSON={style}
              pointToLayer={getPointToLayerCallback(style)}
              onEachFeature={bindTooltipPopup}
            />
          );
        }

        return <React.Fragment key={id} />;
      })}

      <AsyncMapLayer
        key="highlight"
        component={GeoJSON}
        filename={highlightInfo.location}
        style={highlightInfo.style}
        pointToLayer={pointToLayerHighlight}
        filter={filterHighlightElementClosure(highlightElement?.ID)}
      />
    </>
  );
};

export default MapLayers;
