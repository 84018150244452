// All layer types.
export const TYPE_WMS_TILE_LAYER = 'wms_tile_layer';
export const TYPE_TILE_LAYER = 'tile_layer';
export const TYPE_GEOJSON = 'geojson';
export const TYPE_STATION = 'station';
export const TYPE_SUB_MANAGEMENT_UNIT = 'sub_management_unit';
export const TYPE_HYDROGRAPHY = 'hydrography';
export const TYPE_TOPONYM = 'toponym';
export const TYPE_PRECIPITATION = 'precipitation';

// Lists all layer types that need a URL to work correctly.
export const layerTypesWithURL = [TYPE_WMS_TILE_LAYER, TYPE_TILE_LAYER, TYPE_GEOJSON];
