import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Fab, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    '& .MuiFab-label': {
      display: 'block',
      lineHeight: '0.7rem',
    },
  },
  day: {
    display: 'block',
  },
  month: {
    display: 'block',
    fontSize: '0.6rem',
  },
}));

const CurrentDate = () => {
  const classes = useStyles();
  const date = useSelector(({ mainDate }) => mainDate);
  const dispatch = useDispatch();

  const dateObj = dayjs(date);
  const day = dateObj.format('DD');
  const month = dateObj.format('MMM');

  const handleClick = () =>
    dispatch({ type: 'DATE_SET', date: dayjs(new Date()).format('YYYY-MM-DD') });

  return (
    <Tooltip title="Revenir à aujourd'hui">
      <Fab color="secondary" className={classes.button} onClick={handleClick}>
        <span className={classes.day}>{day}</span>
        <span className={classes.month}>{month}</span>
      </Fab>
    </Tooltip>
  );
};

export default CurrentDate;
