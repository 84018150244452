import React from 'react';
import { useDispatch } from 'react-redux';
import useLayers from '../hooks/useLayers';

const LayersDispatch = () => {
  const groups = useLayers();
  const dispatch = useDispatch();

  /**
   * Dispatch initial active layers on mount
   */
  React.useEffect(() => {
    const initialLayers = [];
    groups.forEach(({ groupId, layers }) => {
      layers.forEach(layer => {
        if (layer.isDisplayedByDefault && groupId !== 'none') {
          initialLayers.push({ id: layer.id, type: layer.type });
        }
      });
    });

    dispatch({ type: 'LAYER_TOGGLE', layers: initialLayers, force: true });
  }, [groups, dispatch]);

  return null;
};

export default LayersDispatch;
